/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.earnings-card {
	border-radius: 15px !important;
	cursor: pointer !important;

	.card {
		border-radius: 15px !important;
	}
}

.paper {
	border-radius: 15px !important;
	padding: 15px !important;
	box-shadow: 0 0 20px rgba(0, 0, 0, .05) !important;
}

.paper1 {
	border-radius: 15px !important;
	padding: 15px !important;
	box-shadow: 0 0 20px rgba(0, 0, 0, .05) !important;
	width: 49% !important;
}

.house_kping_sts_tbl th {
	font-size: 12px !important;
	padding: 5px !important;
}

.p_fix {
	position: absolute !important;
}

.error_msg_lbl {
	color: #ea5455 !important;
}

.border_red {
	border-color: 1px solid #ea5455 !important;
}

// scrollbar webkit
::-webkit-scrollbar:horizontal {
	display: auto !important;
	height: 0.4rem !important;
}

::-webkit-scrollbar {
	width: 0.5rem;
	border-radius: 50rem;
}

iframe {
	pointer-events: auto;
}

::-webkit-scrollbar-thumb {
	background-color: #7367f0 !important;
	border-radius: 50rem;
}

::-webkit-scrollbar-track {
	border: 1px solid;
	border-radius: 50rem;
	border-color: #c0c0c0 !important;
}

.floor-dropdown {
	z-index: 99;
}

.discount-error {
	margin-left: 80px;
}

.date-error {
	margin-left: 115px;
}

.name-error {
	margin-left: 120px;
}

.Amount {
	margin-left: 5px;
}

.roomsBookedDropdown {
	width: 120px;
}

.discountTypeDropdown {
	width: 130px;
}

.discountField {
	width: 135px;
}

.mealCol {
	width: 300px;
}

.mealDropdown {
	width: 300px;
}

.occupantsCol {
	width: 45%;
}

.adultsDropdown {
	width: 70px;
}

// .paymentType {
// 	margin-left: 116px;
// }
.bookingSource {
	margin-left: 116px;
}

// .mealName {
// 	margin-left: 117px;
// }
// .mealType {
// 	margin-left: 84px;
// }
.editMealName {
	margin-left: 83px;
}

.editMealType {
	margin-left: 90px;
}

.paymentTypeName {
	margin-left: 68px;
}

.typeLabel {
	width: 110px;
}

// .mealTypePrice {
// 	margin-left: 52px;
// }
.bookingSourceName {
	margin-left: 65px;
}

.editPaymentModeName {
	margin-left: 66px;
}

.modal-backdrop.show {
	opacity: 0.75;
}

:root {
	--main-color: rgb(104, 117, 217);
	--main-color-hover: rgb(104, 117, 217, 0.2);
	--track-color: #ddd;
	--mark-color: #aaaaaa;
}

.customSlider {
	/* max width of your slider */
	max-width: 300px;
	/* Optional: Only If you want to center your slider in the page */
	margin: auto;
}

.customSlider-track {
	/* Top value to align your track to the center of your thumb */
	top: 8px;
	/* thickness of the track */
	height: 4px;
	/* default color of your track */
	background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
	/* color of the track before the thumb */
	background: var(--main-color);
}

.customSlider-thumb {
	cursor: pointer;
	/*color for the thumb */
	background: var(--main-color);
	/* shape of the thumb: circle */
	width: 20px;
	height: 20px;
	border-radius: 100%;
	/* remove default outline when selected */
	outline: none;
}

.customSlider-thumb:hover {
	box-shadow: 0 0 0 8px var(--main-color-hover);
}

.customSlider-mark {
	cursor: pointer;
	top: 6px;
	width: 1.5px;
	height: 8px;
	background-color: var(--mark-color);
}

.customSlider-mark.customSlider-mark-before {
	background-color: var(--main-color);
}

.customSlider-mark.customSlider-mark-active {
	display: none;
}

.globalDropdown.dropdown-menu[data-popper-placement]:not([data-popper-placement^=top-]) {
	top: 4.2rem !important;
}

.globalDropdown {
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}

.css-11mde6h-MuiPaper-root {
	box-shadow: none !important;
}

.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
	width: 130px;
}

.MuiTable-root .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
	display: none !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
	align-items: baseline !important;
}

.css-1ex1afd-MuiTableCell-root {
	text-align: center !important;
}

button[data-testid="Print-iconButton"] {
	display: none !important;
}

fieldset[aria-label="Show/Hide Table Columns"] {
	padding-left: 20px;
}

.posCategories .nav-link {
	background-color: #7367f0;
	color: #fff;
	margin: 2px;
	border-radius: 5px;
}

// #fca043
// #30cfe8
// #f3f2f7
.posCategories .nav-link.active {
	background-color: #808080;
	color: #fff;
}

.posCategories .nav-link:after {
	background: transparent !important;
}

.QuickTable {
	border-bottom-width: 1px;
}

.QuickTable td {
	border-bottom: transparent;
}


.tss-1akey0g-MUIDataTableHeadCell-data {
	width: max-content;
}

.Voucherlogo {
	width: 100px;
	height: 100px;
}

.durationCard .card {
	box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.durationCard {
	h2 {
		font-size: 1.5rem;
	}

	p {
		font-size: 0.9rem;
	}
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
	display: none !important;
}

.alice-carousel__dots {
	margin: 0px 3px 20px !important;
}